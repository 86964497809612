import { cva, type VariantProps } from 'class-variance-authority'
import React from 'react'
import { cn } from '#app/utils/misc'

const inputVariants = cva(
	cn(
		'flex m-2 border border-input bg-background text-sm ring-offset-background placeholder:text-muted-foreground aria-[invalid]:border-input-invalid',
		'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
		'file:border-0 file:bg-transparent file:text-sm file:font-medium',
		'disabled:cursor-not-allowed disabled:opacity-50',
	),
	{
		variants: {
			variant: {
				default: 'w-auto',
				width: 'w-full',
			},
			size: {
				default: 'h-9 px-3 py-2',
				sm: 'h-5 rounded-md px-3',
				md: 'h-7 rounded-md px-3',
				lg: 'h-9 rounded-md px-3',
			},
		},
		defaultVariants: {
			variant: 'default',
			size: 'default',
		},
	},
)

export interface FormInputProps
	extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'>,
		VariantProps<typeof inputVariants> {}

const FormInput = React.forwardRef<HTMLInputElement, FormInputProps>(
	({ className, variant, size, type, ...props }, ref) => {
		return (
			<input
				type={type}
				className={cn(inputVariants({ variant, size }), className)}
				ref={ref}
				{...props}
			/>
		)
	},
)
FormInput.displayName = 'FormInput'

export { FormInput }
